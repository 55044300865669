/* WelcomePage.css */

/* Default Styles for Laptops and Desktops */
body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    background: url('/public/welovexmasgirl.webp') no-repeat center center fixed;
    background-size: cover;
    line-height: 1.5;
}

.welcome-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    color: #1E5631; /* Evergreen text for a natural, festive vibe */
    padding: 20px;
    margin-top: 60px; /* Adjusted for navbar offset */
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    z-index: 1000;
}

.navbar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.welcome-title {
    font-family: 'Fredoka One', cursive;
    color: #D12F2F; /* Holly Red */
    font-size: 1.6rem;
    margin-bottom: 0;
    animation: rotateColors 5s infinite alternate;
}

.welcome-subtitle {
    font-size: 1rem;
    color: #1E5631; /* Evergreen */
}

.signup-form {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.signup-form input {
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #1E5631;
    border-radius: 5px 0 0 5px;
    outline: none;
}

.signup-form button {
    padding: 10px 20px;
    font-size: 1rem;
    background: #FFD700; /* Gold Glitter */
    color: #1E5631;
    border: none;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.signup-form button:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px #FFD700;
}

.social-links {
    display: flex;
    gap: 10px;
}

.social-links a {
    display: inline-block;
    padding: 10px 20px;
    background: #FF5733; /* Holly Red for buttons */
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s;
}

.social-links a:hover {
    background: #FFC107; /* Lighter gold for hover */
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px;
    gap: 20px;
}

.card {
    background: #F4E3C1; /* Warm Beige for a cozy feel */
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, opacity 0.3s ease-in-out;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
}

.card-title {
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    color: #D12F2F; /* Holly Red */
    margin-bottom: 10px;
}

.card-text {
    font-size: 1rem;
    color: #1E5631; /* Evergreen */
}

.hidden-snowflake {
    cursor: pointer;
    font-size: 1.5rem;
    margin-left: 15px;
    transition: transform 0.3s ease;
}

.hidden-snowflake:hover {
    transform: rotate(20deg) scale(1.2);
    color: #FFD700; /* Gold Glitter */
}

/* Media Queries for Mobile Styling */

/* For Tablets and Smaller Devices */
@media screen and (max-width: 768px) {
    .contract-section{
        margin-top: 150px !important;
    }
    .navbar-content {
        flex-direction: column;
        gap: 10px;
    }

    .welcome-title {
        font-size: 1.4rem;
    }

    .welcome-subtitle {
        font-size: 1rem;
    }

    .signup-form {
        flex-direction: column;
        gap: 10px;
    }

    .signup-form input,
    .signup-form button {
        width: 100%;
        max-width: 400px;
    }

    .social-links {
        padding: 10px !important;
        flex-direction: row;
        gap: 5px;
    }

    .cards-container {
        flex-direction: column;
        gap: 20px;
    }

    .card {
        width: 90%;
        max-width: 350px;
    }

    .card-title {
        font-size: 1.4rem;
    }
}

/* For Mobile Phones */
@media screen and (max-width: 480px) {
    .welcome-title {
        font-size: 1.5rem;
        text-align: center;
    }

    .welcome-subtitle {
        font-size: 0.9rem;
        text-align: center;
    }

    .navbar {
        padding: 5px 10px;
    }

    .navbar-content {
        flex-direction: column;
        align-items: center;
    }

    .card-title {
        font-size: 1.2rem;
    }

    .card-text {
        font-size: 0.9rem;
    }

    .signup-form input {
        font-size: 0.9rem;
    }

    .signup-form button {
        font-size: 0.9rem;
    }
}

/* Animations and Glow Effects */

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

@keyframes rotateColors {
    0% { color: #D12F2F; } /* Holly Red */
    25% { color: #FFD700; } /* Gold Glitter */
    50% { color: #1E5631; } /* Evergreen */
    75% { color: #7FB5FF; } /* Sky Blue */
    100% { color: #F47B89; } /* Candy Cane Pink */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes glowText {
    0% {
        text-shadow: 0 0 5px #FFD700, 0 0 10px #FFD700, 0 0 15px #FFD700;
    }
    100% {
        text-shadow: 0 0 20px #FFD700, 0 0 30px #FFD700, 0 0 40px #FF5733;
    }
}

/* Contract Section Announcement */
.contract-section {
    background: #FFD700; /* Gold Glitter */
    color: #1E5631; /* Evergreen */
    padding: 15px;
    margin-top: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 2s ease-in-out forwards, glowText 1.5s infinite alternate; /* Fade-in and glowing text */
}

.contract-announcement {
    font-family: 'Fredoka One', cursive;
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.contract-text {
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
}
