@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes snow {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh);
    opacity: 0;
  }
}

.snowflake {
  position: fixed; /* Fixed to viewport */
  top: -10px;
  color: white;
  font-size: 1.5em;
  animation: snow 10s linear infinite;
  pointer-events: none; /* Ensures no interference with interactions */
}

.snow-container {
  position: fixed; /* Fixed to prevent DOM flow issues */
  top: 0;
  left: 0;
  width: 100vw; /* Confined to the viewport */
  height: 100vh;
  overflow: hidden;
  z-index: -1; /* Ensures snow stays behind all content */
}

.snow-container span {
  position: absolute;
  top: -10px;
  left: calc(var(--i) * 10%);
  animation-delay: calc(var(--i) * 0.5s);
  animation-duration: calc(var(--i) * 2s + 5s);
}
